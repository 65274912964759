import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "gentle-introduction-to-solidjs"
    }}>{`Gentle introduction to SolidJS`}</h1>
    <h5 {...{
      "id": "june-2-2022--softserves-internal-tech-community"
    }}>{`June 2, 2022 | `}<a parentName="h5" {...{
        "href": "https://www.softserveinc.com/en-us"
      }}>{`SoftServe`}</a>{`'s internal tech community`}</h5>
    <h5 {...{
      "id": "november-18-2022--modern-frontends-live-conference"
    }}>{`November 18, 2022 | Modern Frontends Live conference`}</h5>
    <hr></hr>
    <ul>
      <li parentName="ul">{`📊 `}<strong parentName="li">{`Slides`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://slides.com/kajetansw/intro-to-solidjs/fullscreen"
        }}>{`https://slides.com/kajetansw/intro-to-solidjs/fullscreen`}</a></li>
      <li parentName="ul">{`🎥 `}<strong parentName="li">{`Recording`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=0efOc8D39Ow"
        }}>{`https://www.youtube.com/watch?v=0efOc8D39Ow`}</a></li>
      <li parentName="ul">{`⌚ `}<strong parentName="li">{`Duration`}</strong>{`: ~60 minutes`}</li>
      <li parentName="ul">{`👨‍💻 `}<strong parentName="li">{`Form`}</strong>{`: talk + live-coding session`}</li>
    </ul>
    <hr></hr>
    <p>{`SolidJS has brought a lot of attention lately in the web world. It makes a solid (pun intended) competition to the most popular web frameworks, and not without a reason.
But I won’t spoil it for you, you have to see for yourself!`}</p>
    <p>{`This talk serves as a gentle introduction to SolidJS. During the event, you’ll hear about basic concepts behind the framework and what makes it stand out - all based on a real-world
example of a small feature that’ll be live-coded in front of you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      